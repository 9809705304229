import axios from "axios";
import { getHostPythonAPIBaseUrl } from "config";
// const { IP_BACKEND_HOST } = require("../config/env");

class ServiceRequests {
  async getPagination(page: number, pageSize: number, filter: any, date: string) {
    console.log(`page: ${page}, pageSize: ${pageSize}, filter: ${filter}, date: ${date}`)
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/assistances?page=${page}&limit=${pageSize}&specialty=${filter}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error getPagination() ", error);
    }
  }

  async getPaginationCompanyCall(page: number, pageSize: number, dateFilter: string = "All", startDate: Number = 0, endDate: Number = 0,search: string | null = null) {
    const token = localStorage.getItem("access_token");
    let url = `${getHostPythonAPIBaseUrl()}/company/call/pagination?page=${page}&limit=${pageSize}&date_filter=${dateFilter}&start_date=${startDate}&end_date=${endDate}`;
    if(search) {
      url += `&search=${encodeURI(search)}`
    }
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error getPagination() ", error);
    }
  }


  async getPaginationAssistanceInquiry(page: number, pageSize: number, filter: string, date: string) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/assistances?page=${page}&limit=${pageSize}&specialty=${filter}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        }
      });

      return response.data
    } catch (error) {
      console.error("error getPaginationAssistanceInquiry() ", error);
    }
  }



  async getRequestsPagination(page: number, pageSize: number, filter: any, date: string) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/assistances?page=${page}&limit=${pageSize}&specialty=${filter}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error);
    }
  }




  async getTotal(period: string) {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/company/call/count/?period=${period}`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }
  async getAbsolute() {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/company/call/count/absolute`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }
  async overviewChartService() {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/company/call/count/chart`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }


  async pieServiceChartData() {
    const token = localStorage.getItem("access_token");
    const url = `${getHostPythonAPIBaseUrl()}/company/call/count/group?field=specialty.name`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }



  async getServiceByChat(chatId: string) {
    const url = `${getHostPythonAPIBaseUrl()}/assistances/get-by-chat?id=${chatId}`;
    console.log('url: ', url);
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }


  async getByUser(userId: any,) {
    const url = `${getHostPythonAPIBaseUrl()}/assistances/get-by-user-id?id=${userId}`;
    try {
      const token = localStorage.getItem("access_token");
      const response = await axios.get(url, {
        headers: {
          "Authorization": token,
        },
      });
      return response.data;
    } catch (error) {
      console.error("error : ", error)
    }
  }

}
export default ServiceRequests;
