import React, { useEffect, useState } from "react";
import FlexBetween from "components/FlexBetween";
import Header from "components/Header";
import {
  // DownloadOutlined,
  PointOfSale,
  PersonAdd,
  CalendarMonthOutlined,
  TimelineOutlined,
} from "@mui/icons-material";

import FactoryIcon from "@mui/icons-material/Factory";

import {
  Box,
  Button,
  ButtonGroup,
  Switch,
  FormControlLabel,
  useTheme,
  Pagination,
  MenuItem,
  Select,
} from "@mui/material";
import { Typography } from "@mui/material";
import DateFilter from "components/DateFilter";
import BreakdownChart from "components/BreakdownChart";
import OverviewChart from "components/OverviewChart";
import StatBox from "components/StatBox";
import useDashboardViewModel from "view_models/dashboard";
import NotificationAsking from "components/NotificationAsking";
import BuildIcon from "@mui/icons-material/Build";
import GroupIcon from "@mui/icons-material/Group";
import TableDashborad from "./tableDashboard";
import SearchBar from "components/SearchBar/SearchBar";

const tabTopStyle = {
  color: "var(--gray-gray-900, #7E7E7E)",
  fontFeatureSettings: "'calt' off",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px", // 187.5%
  letterSpacing: "-0.32px",
};
const tabTopCurrentStyle = {
  color: "#FF7C06",
  fontFeatureSettings: "'calt' off",
  fontFamily: "Inter",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "30px", // 187.5%
  letterSpacing: "-0.32px",
};

const Dashboard: React.FC = () => {
  const viewModel = useDashboardViewModel();
  const theme = useTheme();

  useEffect(() => {
    console.log(viewModel.emitSearch);
    viewModel.setData([]);
    const fetchData = async () => {
      await viewModel.fetchPaginationData();
    };
    fetchData();
  }, [
    viewModel.emitSearch,
    viewModel.page,
    viewModel.pageSize,
    viewModel.currentTab,
    viewModel.dateFilter,
    viewModel.isFetchData,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      viewModel.fetchCompanyStatus();
      viewModel.fetchPieCompanyChartData();
    };
    if (viewModel.currentTab === 3) {
      fetchData();
    }
  }, [viewModel.currentTab]);

  useEffect(() => {
    const fetchData = async () => {
      viewModel.fetchOverviewData();
      viewModel.fetchServiceStatus();
      viewModel.fetchPieServiceChartData();
    };
    if (viewModel.currentTab === 1) {
      fetchData();
    }
  }, [viewModel.currentTab]);

  useEffect(() => {
    const fetchData = async () => {
      viewModel.fetchOverviewUser();
      viewModel.fetchPieUserChartData();
      viewModel.fetchUserStatus();
    };
    if (viewModel.currentTab === 2) {
      fetchData();
    }
  }, [viewModel.currentTab]);

  useEffect(() => {
    console.log(viewModel.searchTerm)
  }, [viewModel.searchTerm]);

  return (
    <Box m="1.5rem 2.5rem">
      <NotificationAsking />
      <FlexBetween>
        <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
      </FlexBetween>
      <Box display="flex" mt={4}>
        <Box
          onClick={() => {
            viewModel.setCurrentTab(1);
          }}
          display="flex"
          alignItems="center"
        >
          <BuildIcon
            style={{
              color: `${viewModel.currentTab === 1 ? "#FF7C06" : "#7E7E7E"} `,
            }}
          ></BuildIcon>
          <Typography
            style={
              viewModel.currentTab === 1 ? tabTopCurrentStyle : tabTopStyle
            }
            ml={1}
            mr={4}
          >
            Services
          </Typography>
        </Box>
        <Box
          onClick={() => {
            viewModel.setCurrentTab(2);
          }}
          display="flex"
          alignItems="center"
          mr={4}
        >
          <GroupIcon
            style={{
              color: `${viewModel.currentTab === 2 ? "#FF7C06" : "#7E7E7E"} `,
            }}
          ></GroupIcon>
          <Typography
            style={
              viewModel.currentTab === 2 ? tabTopCurrentStyle : tabTopStyle
            }
            ml={1}
          >
            Users
          </Typography>
        </Box>

        <Box
          onClick={() => {
            viewModel.setCurrentTab(3);
          }}
          display="flex"
          alignItems="center"
          mr={4}
        >
          <FactoryIcon
            style={{
              color: `${viewModel.currentTab === 3 ? "#FF7C06" : "#7E7E7E"} `,
            }}
          ></FactoryIcon>
          <Typography
            style={
              viewModel.currentTab === 3 ? tabTopCurrentStyle : tabTopStyle
            }
            ml={1}
          >
            Companies
          </Typography>
        </Box>
      </Box>
      <Box
        mt="24px"
        display="flex"
        flexWrap="wrap"
        gap="20px"
        maxHeight={400}
        alignItems="flex-start"
      >
        <Box
          display={"inline-flex"}
          flexWrap="wrap"
          height={400}
          sx={{ width: "400px" }}
        >
          <StatBox
            title={`Total ${
              viewModel.currentTab === 1
                ? "services"
                : viewModel.currentTab === 2
                ? "user"
                : "active companies"
            }`}
            value={
              viewModel.currentTab === 1
                ? viewModel.absoluteTotalServices
                : viewModel.currentTab === 2
                ? viewModel.absoluteTotalUsers
                : viewModel.absoluteTotalCompanies
            }
            increase={false}
            increasePercentage={
              viewModel.currentTab === 1
                ? viewModel.userStatus.percentageMonth
                : viewModel.currentTab === 2
                ? viewModel.serviceStatus.percentageMonth
                : viewModel.companyStatus.percentageMonth
            }
            description="For ever"
            icon={
              <PersonAdd
                // @ts-ignore
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title={`${
              viewModel.currentTab === 1
                ? "Services"
                : viewModel.currentTab === 2
                ? "New Users"
                : "New active companies"
            } today`}
            value={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.today
                : viewModel.currentTab === 2
                ? viewModel.userStatus.today
                : viewModel.companyStatus.today
            }
            increase={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.incrementDay
                : viewModel.currentTab === 2
                ? viewModel.userStatus.incrementDay
                : viewModel.companyStatus.incrementDay
            }
            increasePercentage={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.percentageDay
                : viewModel.currentTab === 2
                ? viewModel.userStatus.percentageDay
                : viewModel.companyStatus.percentageDay
            }
            description="Since yesterday"
            icon={
              <PointOfSale
                // @ts-ignore
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title={`Monthly ${
              viewModel.currentTab === 1
                ? "Services"
                : viewModel.currentTab === 2
                ? "Users"
                : "Companies"
            }`}
            value={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.currentMonth
                : viewModel.userStatus.currentMonth
            }
            increase={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.incrementMonth
                : viewModel.currentTab === 2
                ? viewModel.userStatus.incrementMonth
                : viewModel.companyStatus.incrementMonth
            }
            increasePercentage={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.percentageMonth
                : viewModel.currentTab === 2
                ? viewModel.userStatus.percentageMonth
                : viewModel.companyStatus.percentageMonth
            }
            description="Since last month"
            icon={
              <CalendarMonthOutlined
                // @ts-ignore
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
          <StatBox
            title={`Yearly ${
              viewModel.currentTab === 1
                ? "Services"
                : viewModel.currentTab === 2
                ? "New users"
                : "New companies"
            }`}
            value={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.currentYear
                : viewModel.currentTab === 2
                ? viewModel.userStatus.currentYear
                : viewModel.companyStatus.currentYear
            }
            increase={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.incrementYear
                : viewModel.currentTab === 2
                ? viewModel.userStatus.incrementYear
                : viewModel.companyStatus.incrementYear
            }
            increasePercentage={
              viewModel.currentTab === 1
                ? viewModel.serviceStatus.percentageYear
                : viewModel.currentTab === 2
                ? viewModel.userStatus.percentageYear
                : viewModel.companyStatus.percentageYear
            }
            description="Since last year"
            icon={
              <TimelineOutlined
                // @ts-ignore
                sx={{ color: theme.palette.secondary[300], fontSize: "26px" }}
              />
            }
          />
        </Box>
        {/* @ts-ignore */}
        <Box
          gridColumn="span 4"
          gridRow="span 3"
          // @ts-ignore
          backgroundColor={theme.palette.background.alt}
          p="1.5rem"
          borderRadius="0.3rem"
          width={"460px"}
          height={400}
        >
          {/*@ts-ignore*/}
          <Typography variant="h4" sx={{ color: theme.palette.secondary[100] }}>
            {viewModel.currentTab === 1
              ? "Specialty"
              : viewModel.currentTab === 2
              ? "Plataform"
              : "Query"}
          </Typography>

          <Typography variant="h6">
            Absolute total:{" "}
            <b>
              {viewModel.currentTab === 1
                ? viewModel.absoluteTotalServices
                : viewModel.currentTab === 2
                ? viewModel.absoluteTotalPlatform
                : viewModel.absoluteTotalQuery}
            </b>
          </Typography>

          <BreakdownChart
            isDashboard={false}
            categories={
              viewModel.currentTab === 1
                ? viewModel.pieServicesChart
                : viewModel.currentTab === 2
                ? viewModel.pieUserChart
                : viewModel.pieCompanyChart
            }
          />
        </Box>
      </Box>

      <Box mt={3}>
        <Box display={"flex"} alignItems="center" justifyContent="flex-start">
          <DateFilter
            width="200px"
            onChange={(value: any) => {
              if (value === "Custom") return;
              viewModel.setDateFilter(value);
            }}
            onCustomerFilter={(start: Number, end: Number) => {
              viewModel.setDateStartFilter(start);
              viewModel.setDateEndFilter(end);
              viewModel.setIsFetchData(!viewModel.isFetchData);
            }}
          ></DateFilter>
          {/* <Box mt={4} ml={4}>
            <b>Total Filtered:</b>{" "}
            <span>{String(viewModel.totalFiltered)}</span>
          </Box> */}
        </Box>

        <Box mt={3}>
          <SearchBar
            width="400px"
            inputCallback={(e: any) => viewModel.setSearchTerm(e.target.value)}
            buttonCallback={() => {
              console.log("Emit search term")
              viewModel.setEmitSearch(!viewModel.emitSearch);
            }}
            placeholder={"Search ..."}
          />
        </Box>

        {viewModel.data && viewModel.data.length > 0 && (
          <TableDashborad
            currentTab={viewModel.currentTab}
            data={viewModel.data}
          ></TableDashborad>
        )}

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop={2}
          marginBottom={2}
        >
          <Pagination
            count={viewModel.totalPages}
            page={viewModel.page}
            onChange={(event, newPage) => viewModel.handlePageChange(newPage)}
            size="large"
            disabled={!viewModel.data || viewModel.data.length === 0}
          />

          <Box
            marginLeft={2}
            marginBottom={2}
            display="flex"
            flexDirection="column"
            justifyContent="start"
            alignItems="start "
          >
            <label>Items per page</label>
            <Select
              style={{ height: "30px" }}
              value={viewModel.pageSize}
              onChange={viewModel.handleRowsPerPageChange}
            >
              {viewModel.pageSizes.map((size) => (
                <MenuItem key={size + "  - size"} value={size}>
                  {size}
                </MenuItem>
              ))}
            </Select>

            <NotificationAsking></NotificationAsking>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
